// TODO - Type 'any' needs to be fixed.
/* eslint-disable no-mixed-spaces-and-tabs */
import BuildOutlinedIcon from "@mui/icons-material/BuildOutlined";
import FactoryOutlinedIcon from "@mui/icons-material/FactoryOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import StickyNote2OutlinedIcon from "@mui/icons-material/StickyNote2Outlined";
import TimerOffOutlinedIcon from "@mui/icons-material/TimerOffOutlined";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import TodayOutlinedIcon from "@mui/icons-material/TodayOutlined";
import WorkHistoryOutlinedIcon from "@mui/icons-material/WorkHistoryOutlined";
import { Input, InputLabel, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import format from "date-fns/format";
import React, { useCallback } from "react";

import { SkillRepresentationCell } from "../../../../../export/cross-skilling";
import {
	formatDate,
	getRepLevel,
	getRepType,
} from "../../../../../export/helpers";
import {
	JOB,
	SENIORITY,
	SKILLS,
	WORKCENTER,
} from "../../../../assets/constants";
import JobColor from "../../../../design/ui/job-color";

interface ShiftMetaDataProps {
	shiftMetadata: any;
	jobRoleMetadata: any;
	workCenterMetadata: any;
	allSkillLevels: any;
	confirmedWorkersCount: number | undefined;
}
const ShiftMetaData: React.FC<ShiftMetaDataProps> = ({
	shiftMetadata,
	jobRoleMetadata,
	workCenterMetadata,
	allSkillLevels,
	confirmedWorkersCount,
}) => {
	const shiftByType = shiftMetadata?.jobId ? JOB : SKILLS;
	// for flex scheduler we use skills value and for worker requests we use shiftSkills below
	// According to new changes in signup tracking API for worker request shift details dialog
	const skills = shiftMetadata?.skills || shiftMetadata?.shiftSkills;
	console.log(skills, "skills");
	const allSkillsObj = skills?.map((skill: any) => {
		const matchingObject = allSkillLevels.find(
			(level: any) => level.level === skill.level
		);
		if (matchingObject) {
			const { repCode, foreColorCode, backColorCode, value } = matchingObject;
			return {
				...skill,
				repCode,
				foreColorCode,
				backColorCode,
				value,
			};
		} else {
			return null;
		}
	});

	const closedForInterest = shiftMetadata?.windowClosingTime
		? new Date().getTime() >=
		  new Date(shiftMetadata.windowClosingTime).getTime()
		: false;

	const remainingHours = shiftMetadata?.windowClosingTime
		? Math.floor(
				((new Date(shiftMetadata.windowClosingTime).getTime() -
					new Date().getTime()) /
					(1000 * 60 * 60)) %
					24
		  )
		: -1;

	const getWorkCenterName = useCallback(() => {
		let returnValue = "-";
		if (workCenterMetadata) {
			returnValue = workCenterMetadata.name;
		}
		return returnValue;
	}, [workCenterMetadata]);

	const getLocationName = useCallback(() => {
		const returnValue = "-";
		if (workCenterMetadata) {
			if (
				Object.prototype.hasOwnProperty.call(workCenterMetadata, "location")
			) {
				if (
					Object.prototype.hasOwnProperty.call(
						workCenterMetadata.location,
						"name"
					)
				) {
					return workCenterMetadata.location.name;
				}
			}
		}
		return returnValue;
	}, [workCenterMetadata]);

	if (!shiftMetadata) return null;
	return (
		<Box
			sx={{
				padding: "45px 45px 18px 45px",
				width: "100%",
			}}
		>
			<Box mb={1.5}>
				<Typography marginLeft='4px' variant='h6'>
					Shift Details
				</Typography>
			</Box>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					gap: "8px",
				}}
			>
				<Box
					sx={{
						display: "grid",
						gridTemplateColumns: "1fr 1fr 1fr",
					}}
				>
					{/* {JSON.stringify(workCenterMetadata) !== "{}" && ( */}
					<>
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								gap: "4px",
								alignItems: "center",
							}}
						>
							<LocationOnOutlinedIcon
								sx={{
									color: "#0000008f",
								}}
							/>
							<Typography>{shiftMetadata?.shiftLocationName ?? "-"}</Typography>
						</Box>
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								gap: "4px",
								alignItems: "center",
							}}
						>
							<FactoryOutlinedIcon sx={{ color: "#0000008f" }} />
							<Typography>
								{shiftMetadata?.shiftWorkcenterName ?? "-"}
							</Typography>
						</Box>
					</>
					{/* )} */}
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							gap: "4px",
							alignItems: "center",
						}}
					>
						<PersonOutlinedIcon sx={{ color: "#0000008f" }} />
						<Typography>
							{confirmedWorkersCount !== undefined
								? `${confirmedWorkersCount} / ${shiftMetadata.numberOfWorkersNeeded} workers confirmed`
								: `${shiftMetadata.slotsFilled} / ${shiftMetadata.numberOfWorkersNeeded} workers confirmed`}
						</Typography>
						{/* Pending Confirmation */}
						{/* <Tooltip title='Info for this'>
							<InfoOutlinedIcon
								sx={{
									margin: "4px",
									color: "#0000008f",
									fontSize: "20px",
								}}
							/>
						</Tooltip> */}
					</Box>
				</Box>
				<Box
					sx={{
						display: "grid",
						gridTemplateColumns: "1fr 1fr 1fr",
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							gap: "4px",
						}}
					>
						<TodayOutlinedIcon
							sx={{
								color: "#0000008f",
							}}
						/>
						<Typography>
							{/* {shiftMetadata.startDateTime
								? format(
										new Date(shiftMetadata?.startDateTime),
										"d MMM yyyy, h:mm a"
								  )
								: null}{" "}
							-{" "}
							{shiftMetadata.endDateTime
								? format(new Date(shiftMetadata?.endDateTime), "h:mm a")
								: null}{" "} */}
							{shiftMetadata?.startDateTime &&
								shiftMetadata?.endDateTime &&
								`${formatDate({
									dateStr: shiftMetadata?.startDateTime,
									formatType: "SHORT_DATE",
									endDateStr: shiftMetadata?.endDateTime,
								})} ${formatDate({
									dateStr: shiftMetadata?.startDateTime,
									formatType: "TIME_RANGE",
									endDateStr: shiftMetadata?.endDateTime,
								})}`}
						</Typography>
					</Box>
					{(shiftMetadata.shiftAllocationType === SENIORITY ||
						shiftMetadata.shiftAllocationType === WORKCENTER) && (
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								gap: 0.5,
							}}
						>
							{closedForInterest ? (
								<TimerOffOutlinedIcon
									sx={{
										color: "#0000008f",
									}}
								/>
							) : (
								<TimerOutlinedIcon
									sx={{
										color: "#0000008f",
									}}
								/>
							)}
							<Typography>
								{`${
									new Date(shiftMetadata.windowClosingTime).getDate() -
										new Date().getDate() ===
									1
										? `Tomorrow ${format(
												new Date(shiftMetadata.windowClosingTime),
												"h:mm a"
										  )}`
										: new Date(shiftMetadata.windowClosingTime).getDate() -
												new Date().getDate() ===
										  0
										? `Today ${format(
												new Date(shiftMetadata.windowClosingTime),
												"h:mm a"
										  )}`
										: new Date(shiftMetadata.windowClosingTime).getDate() -
												new Date().getDate() ===
										  -1
										? `Yesterday ${format(
												new Date(shiftMetadata.windowClosingTime),
												"h:mm a"
										  )}`
										: formatDate({
												dateStr: shiftMetadata.windowClosingTime,
												formatType: "DATE_WITH_TIME",
										  })
								} ${
									closedForInterest
										? "(window closed)"
										: `(${remainingHours} hour${
												remainingHours > 1 ? "s" : ""
										  } left)`
								}`}
							</Typography>
							{closedForInterest && (
								<Tooltip title='Shift will be assigned to interested workers based on configured rules at the specified time.'>
									<InfoOutlinedIcon
										sx={{
											margin: "4px",
											color: "#0000008f",
											fontSize: "20px",
										}}
									/>
								</Tooltip>
							)}
						</Box>
					)}
				</Box>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						gap: 1,
					}}
				>
					{shiftByType === JOB ? (
						<Box
							sx={{
								display: "flex",
								gap: 1,
							}}
						>
							<WorkHistoryOutlinedIcon
								sx={{
									color: "#0000008f",
								}}
							/>
							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									gap: 0.5,
								}}
							>
								<JobColor
									color={`#${shiftMetadata?.shiftJobRole?.hexColor ?? "#fff"}`}
								/>
								<Typography>
									{shiftMetadata?.shiftJobRole?.name ?? ""}
								</Typography>
							</Box>
						</Box>
					) : (
						<>
							<BuildOutlinedIcon
								sx={{
									color: "#0000008f",
								}}
							/>
							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									gap: 2,
								}}
							>
								{allSkillsObj?.map((skillLevel: any) => (
									<Box
										key={skillLevel.skillName}
										sx={{
											display: "flex",
											alignItems: "center",
											gap: "8px",
										}}
									>
										<SkillRepresentationCell
											level={skillLevel.level}
											backgroundColor={skillLevel.backColorCode}
											foregroundColor={skillLevel.foreColorCode}
											value={skillLevel.value}
											representationType={getRepType(skillLevel.repCode)}
											representationLevel={getRepLevel(
												skillLevel.repCode,
												skillLevel.level
											)}
											border={1}
											height={18}
											width={18}
											backgroundHeight={
												skillLevel.backColorCode ? "28px" : "20px"
											}
											backgroundWidth={
												skillLevel.backColorCode ? "28px" : "20px"
											}
										/>
										<Typography>{skillLevel.skillName}</Typography>
									</Box>
								))}
							</Box>
						</>
					)}
				</Box>
				{shiftMetadata.shiftNote ? (
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							maxWidth: 1100,
							gap: 1,
						}}
					>
						<Box
							sx={{
								display: "flex",
								gap: 1,
							}}
						>
							<StickyNote2OutlinedIcon
								sx={{
									color: "#0000008f",
								}}
							/>

							<Typography>{shiftMetadata?.shiftNote ?? ""}</Typography>
						</Box>
					</Box>
				) : null}
			</Box>
		</Box>
	);
};

export default ShiftMetaData;
