import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import jwt_decode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Redirect, Route } from "react-router-dom";

import { setUser } from "../../../export/user";
import { setWorker } from "../../../export/web-worker";

interface User {
    userRoles: string[];
    id: string;
    showHeatMap: boolean;
    showJobExpiry: boolean;
    skillPriorityEnabled: boolean;
    useObsoleteSkills: boolean;
    showCrossSkilling: boolean;
    customizedBrandingEnabled: boolean;
    certificationsEnabled: boolean;
    skillExpiryEnabled: boolean;
    skillInsightsEnabled: boolean;
    defaultView: string;
    minDurationHrs: number;
    maxDurationHrs: number;
    defaultDurationHrs: number;
    showWorkerProfile: boolean;
    fullTimeSchedule: boolean;
    showShiftSwap: boolean;
    restrictFlexHours: boolean;
    firstName: string;
    lastName: string;
    company: string;
    companyId: string;
    email: string;
    shiftBySkillsMinSkillLevel: number;
    shiftBySkillsEnabled: boolean;
    assignShiftToWorkerEnabled: boolean;
    assignShiftToWorkerHrsBeforeShift: number;
}
interface FetchTokenProps {
    component: any;
    workerApp?: boolean;
    [key: string]: any;
}

export default function FetchToken({
                                       component: Component,
                                       workerApp,
                                       ...rest
                                   }: FetchTokenProps) {
    const dispatch = useDispatch();
    const [isRefreshComplete, setIsRefreshComplete] = useState(false);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        const controller = new AbortController();
        if (workerApp) {
            fetch(`${process.env.REACT_APP_API_END_POINT}/Workers/Refresh`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    refreshToken: localStorage.getItem("worker-refreshToken"),
                    token: localStorage.getItem("worker-token"),
                }),
                signal: controller.signal,
            })
                .then((res: any) => {
                    if (res.status === 200) {
                        return res.json();
                    } else throw new Error(res);
                })
                .then((res) => {
                    if (res.refreshToken && res.token) {
                        dispatch(setWorker());
                        localStorage.setItem("worker-refreshToken", res.refreshToken);
                        localStorage.setItem("worker-token", res.token);
                        setIsRefreshComplete(true);
                    } else {
                        throw new Error("token not found even after 200");
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setIsError(true);
                });
        } else {
            fetch(`${process.env.REACT_APP_API_END_POINT}/Users/Refresh`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    refreshToken: localStorage.getItem("refreshToken"),
                    token: localStorage.getItem("token"),
                }),
                signal: controller.signal,
            })
                .then((res: any) => {
                    if (res.status === 200) {
                        return res.json();
                    } else throw new Error(res);
                })
                .then((res) => {
                    if (res.refreshToken && res.token) {
                        const {
                            userRoles,
                            id,
                            showHeatMap,
                            skillPriorityEnabled,
                            useObsoleteSkills,
                            showJobExpiry,
                            showCrossSkilling,
                            customizedBrandingEnabled,
                            certificationsEnabled,
                            skillExpiryEnabled,
                            skillInsightsEnabled,
                            defaultView,
                            minDurationHrs,
                            maxDurationHrs,
                            defaultDurationHrs,
                            showWorkerProfile,
                            fullTimeSchedule,
                            showShiftSwap,
                            restrictFlexHours,
                            firstName,
                            lastName,
                            company,
                            companyId,
                            email,
                            shiftBySkillsMinSkillLevel,
                            shiftBySkillsEnabled,
                            assignShiftToWorkerEnabled,
                            assignShiftToWorkerHrsBeforeShift,
                        } = jwt_decode(res.token) as User;
                        dispatch(
                            setUser({
                                userRoles,
                                id,
                                showHeatMap,
                                showJobExpiry,
                                skillPriorityEnabled,
                                useObsoleteSkills,
                                minDurationHrs,
                                maxDurationHrs,
                                defaultDurationHrs,
                                showCrossSkilling,
                                customizedBrandingEnabled,
                                certificationsEnabled,
                                skillExpiryEnabled,
                                skillInsightsEnabled,
                                defaultView,
                                showWorkerProfile,
                                fullTimeSchedule,
                                showShiftSwap,
                                restrictFlexHours,
                                firstName,
                                lastName,
                                company,
                                companyId,
                                username: email,
                                shiftBySkillsMinSkillLevel,
                                shiftBySkillsEnabled,
                                assignShiftToWorkerEnabled,
                                assignShiftToWorkerHrsBeforeShift,
                            })
                        );
                        localStorage.setItem("refreshToken", res.refreshToken);
                        localStorage.setItem("token", res.token);
                        setIsRefreshComplete(true);
                    } else {
                        throw new Error("token not found even after 200");
                    }
                })
                .catch((error) => {
                    console.log(error);
                    localStorage.removeItem("refreshToken");
                    localStorage.removeItem("token");
                    localStorage.removeItem("appliedFilters-flex-scheduler");
                    setIsError(true);
                });
        }
        return () => controller?.abort();
    }, [dispatch]); // eslint-disable-line

    if (isError) {
        return <Redirect to={{ pathname: workerApp ? "/wl" : "/login" }} />;
    }

    if (!isRefreshComplete)
        return (
            <Box
                height="100vh"
                width={1}
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
            >
                <CircularProgress />
                <Box mt={1}>
                    <Typography>Logging you in...</Typography>
                </Box>
            </Box>
        );
    else return <Route {...rest} render={(props) => <Component {...props} />} />;
}
