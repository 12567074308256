/* eslint-disable no-mixed-spaces-and-tabs */
// TODO - Type 'any' needs to be fixed.
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import EditIcon from "@mui/icons-material/Edit";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import FactoryOutlinedIcon from "@mui/icons-material/FactoryOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import SendIcon from "@mui/icons-material/Send";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import TimerOffOutlinedIcon from "@mui/icons-material/TimerOffOutlined";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import WorkHistoryOutlinedIcon from "@mui/icons-material/WorkHistoryOutlined";
import { Button, Skeleton } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import format from "date-fns/format";
import isAfter from "date-fns/isAfter";
import isBefore from "date-fns/isBefore";
import isSameDay from "date-fns/isSameDay";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
	formatDate,
	getTotalWorkersCount,
} from "../../../../../export/helpers";
import { ShiftBySkillsTag } from "../../../../../export/shift-scheduler";
import {
	FASTEST,
	JOB,
	SENIORITY,
	SKILLS,
	WORKCENTER,
} from "../../../../assets/constants";
import {
	editDraftShiftDialogOpen,
	getConfirmedWorkersForShift,
} from "../../store/scheduler-slice";
import DeleteShift from "../delete-shift";

const BoxRow = ({ icon, content, fontWeight }: any) => {
	return (
		<Box display='flex' alignItems='center' pb={1}>
			{icon}
			<Box ml={1}>
				<div
					style={{
						whiteSpace: "normal",
						fontSize: "14px",
						fontWeight: `${fontWeight || 400}`,
					}}
				>
					{content}
				</div>
			</Box>
		</Box>
	);
};

export default function TooltipContent({
	appointmentData,
	setShiftIdForShiftDetailsDialog,
	openPublishDraftShiftDialog,
	openShiftDetailsDialog,
	openShiftAttendanceDialog,
	toggleVisibility,
	openShiftCancelDialog,
	openDeleteDraftShiftDialog,
	visible,
	...restProps
}: any) {
	const dispatch = useDispatch();
	const data = appointmentData;
	const shiftByType = data.jobId === 0 ? SKILLS : JOB;
	const shiftAllocationType = appointmentData.shiftAllocationType ?? FASTEST;
	const windowClosingTimeForSeniorityShiftAllocation =
		shiftAllocationType === SENIORITY || shiftAllocationType === WORKCENTER
			? appointmentData.windowClosingTime
			: null;
	// For switching between interested workers and confirmed workers
	const closedForInterest = windowClosingTimeForSeniorityShiftAllocation
		? new Date().getTime() >=
		  new Date(windowClosingTimeForSeniorityShiftAllocation).getTime()
		: false;
	const remainingHours = windowClosingTimeForSeniorityShiftAllocation
		? Math.floor(
				((new Date(windowClosingTimeForSeniorityShiftAllocation).getTime() -
					new Date().getTime()) /
					(1000 * 60 * 60)) %
					24
		  )
		: -1;
	const confirmedCountKey =
		(shiftAllocationType === SENIORITY || shiftAllocationType === WORKCENTER) &&
		!closedForInterest
			? "workersInterested"
			: "slotsFilled";

	const shiftId = data?.id;
	const userRoles = useSelector((state: any) => state.user.userData.userRoles);

	const shiftJob = useSelector((state: any) => {
		if (shiftByType === JOB) {
			// Shift by Job
			const jobRole =
				state.supervisor?.filterData?.allJobRoles.length > 0 &&
				state.supervisor?.filterData?.allJobRoles?.find(
					(jobRole: any) => jobRole.id === data.jobId
				);
			if (jobRole) return { name: jobRole.name };
			else return { name: "-" };
		} else {
			return { name: "-" };
		}
	});

	const shiftSkills = useSelector((state) => {
		if (shiftByType === SKILLS) {
			return data.skills ?? [];
		} else {
			return [];
		}
	});

	const workcenterName = useSelector((state: any) => {
		const workcenter = state.supervisor?.filterData?.allWorkcenters?.filter(
			(workcenter: any) => workcenter.id === data.workCenterId
		);
		if (workcenter.length > 0) {
			return workcenter[0].name;
		} else return "Workcenter";
	});

	const locationName = useSelector((state: any) => {
		const workcenter = state.supervisor?.filterData?.allWorkcenters?.find(
			(workcenter: any) => workcenter.id === data.workCenterId
		);
		if (Object.prototype.hasOwnProperty.call(workcenter, "locationId")) {
			const location = state.supervisor?.filterData?.allLocations?.find(
				(location: any) => location.id === workcenter.locationId
			);
			if (location) {
				return location.name;
			} else return "Location";
		}
	});

	const isAfterShift = () => {
		return (
			isAfter(new Date(), new Date(data?.endDateTime)) ||
			isSameDay(new Date(), new Date(data?.startDateTime))
		);
	};

	const isBeforeShift = () => {
		return isBefore(new Date(), new Date(data?.startDateTime));
	};

	const draftDataContent = (
		<Box width='248px'>
			{shiftByType === JOB && (
				<Box pt={1} pb={1} pl={2.5} pr={2.5}>
					<BoxRow
						icon={<WorkHistoryOutlinedIcon />}
						content={shiftJob.name ?? ""}
						fontWeight='500'
					/>
				</Box>
			)}
			{shiftByType === SKILLS && (
				<Box pt={1} pb={1} pl={2.5} pr={2.5}>
					<ShiftBySkillsTag skills={shiftSkills} />
				</Box>
			)}
			<Box pb={1} pl={2.5} pr={2.5}>
				<BoxRow
					icon={<AccessTimeOutlinedIcon />}
					content={
						<span>
							{formatDate({
								dateStr: data?.startDate,
								formatType: "TIME_RANGE",
								endDateStr: data?.endDate,
							})}
						</span>
					}
				/>
			</Box>
			{locationName && (
				<Box pb={1} pl={2.5} pr={2.5}>
					<BoxRow icon={<LocationOnOutlinedIcon />} content={locationName} />
				</Box>
			)}
			{workcenterName && (
				<Box pb={1} pl={2.5} pr={2.5}>
					<BoxRow icon={<FactoryOutlinedIcon />} content={workcenterName} />
				</Box>
			)}
			<Divider sx={{ marginTop: "10px" }} />
			{isBeforeShift() && (
				<>
					<Button
						color='primary'
						size='large'
						variant='text'
						startIcon={<EditIcon />}
						onClick={(e: any) => {
							dispatch(editDraftShiftDialogOpen(data?.id.toString()));
							toggleVisibility(false);
						}}
						disabled={
							Array.isArray(userRoles) ? userRoles.includes("ReadOnly") : false
						}
						sx={{
							textTransform: "none",
							width: "100%",
							justifyContent: "flex-start",
							paddingLeft: "24px",
						}}
					>
						Edit Shift
					</Button>
					<Divider />
				</>
			)}
			{isBeforeShift() && (
				<>
					<Button
						color='primary'
						size='large'
						variant='text'
						startIcon={<SendIcon />}
						onClick={(e: any) => {
							setShiftIdForShiftDetailsDialog(shiftId);
							openPublishDraftShiftDialog();
							toggleVisibility(false);
						}}
						disabled={
							Array.isArray(userRoles) ? userRoles.includes("ReadOnly") : false
						}
						sx={{
							textTransform: "none",
							width: "100%",
							justifyContent: "flex-start",
							paddingLeft: "24px",
						}}
					>
						Publish Shift
					</Button>
					<Divider />
				</>
			)}

			<Box>
				<DeleteShift
					shiftData={data}
					toggleVisibility={toggleVisibility}
					openDeleteDraftShiftDialog={() => {
						setShiftIdForShiftDetailsDialog(shiftId);
						openDeleteDraftShiftDialog();
					}}
					isDraftShift={true}
				/>
			</Box>
		</Box>
	);

	const publishedDataContent = (
		<Box width='248px'>
			{shiftByType === JOB && (
				<Box pt={1} pb={1} pl={2.5} pr={2.5}>
					<BoxRow
						icon={<WorkHistoryOutlinedIcon />}
						content={shiftJob.name ?? ""}
						fontWeight='500'
					/>
				</Box>
			)}
			{shiftByType === SKILLS && (
				<Box pt={1} pb={1} pl={2.5} pr={2.5}>
					<ShiftBySkillsTag skills={shiftSkills} />
				</Box>
			)}
			<Box pb={1} pl={2.5} pr={2.5}>
				<BoxRow
					icon={<AccessTimeOutlinedIcon />}
					content={
						<span>
							{formatDate({
								dateStr: data?.startDate,
								formatType: "TIME_RANGE",
								endDateStr: data?.endDate,
							})}
						</span>
					}
				/>
			</Box>
			<Box pb={1} pl={2.5} pr={2.5}>
				<BoxRow icon={<LocationOnOutlinedIcon />} content={locationName} />
			</Box>
			<Box pb={1} pl={2.5} pr={2.5}>
				<BoxRow icon={<FactoryOutlinedIcon />} content={workcenterName} />
			</Box>
			{(shiftAllocationType === SENIORITY ||
				shiftAllocationType === WORKCENTER) && (
				<Box pb={1} pl={2.5} pr={2.5}>
					<BoxRow
						icon={
							closedForInterest ? (
								<TimerOffOutlinedIcon />
							) : (
								<TimerOutlinedIcon />
							)
						}
						content={`${
							new Date(windowClosingTimeForSeniorityShiftAllocation).getDate() -
								new Date().getDate() ===
							1
								? `Tomorrow ${format(
										new Date(windowClosingTimeForSeniorityShiftAllocation),
										"h:mm a"
								  )}`
								: new Date(
										windowClosingTimeForSeniorityShiftAllocation
								  ).getDate() -
										new Date().getDate() ===
								  0
								? `Today ${format(
										new Date(windowClosingTimeForSeniorityShiftAllocation),
										"h:mm a"
								  )}`
								: new Date(
										windowClosingTimeForSeniorityShiftAllocation
								  ).getDate() -
										new Date().getDate() ===
								  -1
								? `Yesterday ${format(
										new Date(windowClosingTimeForSeniorityShiftAllocation),
										"h:mm a"
								  )}`
								: formatDate({
										dateStr: windowClosingTimeForSeniorityShiftAllocation,
										formatType: "DATE_WITH_TIME",
								  })
						} ${
							closedForInterest
								? "(window closed)"
								: `(${remainingHours} hour${
										remainingHours > 1 ? "s" : ""
								  } left)`
						}`}
					/>
				</Box>
			)}
			{/* <Box pb={1} pl={2.5} pr={2.5}>
        <BoxRow
          icon={<RemoveRedEyeOutlinedIcon />}
          content={
            <>
              {" "}
              {data?.shiftViews ?? 0}/
              {getTotalWorkersCount(parseInt(data?.shiftViews ?? "0"), parseInt(data?.workersNotified ?? "0"))} Viewed
            </>
          }
        />
      </Box> */}
			<Box pb={1} pl={2.5} pr={2.5}>
				<BoxRow
					icon={<ThumbUpOutlinedIcon />}
					content={
						<>
							{data[confirmedCountKey] ?? "0"}/
							{getTotalWorkersCount(
								parseInt(data[confirmedCountKey] ?? "0"),
								parseInt(data?.numberOfWorkersNeeded) ?? "0"
							)}{" "}
							{(shiftAllocationType === SENIORITY ||
								shiftAllocationType === WORKCENTER) &&
							!closedForInterest
								? "Applied"
								: "Confirmed"}
						</>
					}
				/>
			</Box>
			<Divider sx={{ marginTop: "10px" }} />
			<Box>
				<Button
					color='primary'
					size='large'
					variant='text'
					startIcon={<ReadMoreIcon />}
					onClick={() => {
						setShiftIdForShiftDetailsDialog(shiftId);
						openShiftDetailsDialog();
						toggleVisibility(false);
					}}
					sx={{
						textTransform: "none",
						width: "100%",
						justifyContent: "flex-start",
						paddingLeft: "24px",
					}}
				>
					View Details
				</Button>
			</Box>
			{isAfterShift() && data?.slotsFilled ? (
				<>
					<Divider />
					<Button
						color='primary'
						size='large'
						variant='text'
						startIcon={<FactCheckIcon />}
						sx={{
							textTransform: "none",
							width: "100%",
							justifyContent: "flex-start",
							paddingLeft: "24px",
						}}
						onClick={() => {
							setShiftIdForShiftDetailsDialog(shiftId);
							openShiftAttendanceDialog();
							toggleVisibility(false);
						}}
						disabled={
							Array.isArray(userRoles) ? userRoles.includes("ReadOnly") : false
						}
					>
						Mark Attendance
					</Button>
				</>
			) : null}
			{isBeforeShift() && (
				<>
					<Divider />
					<Button
						color='primary'
						size='large'
						variant='text'
						startIcon={<EditIcon />}
						onClick={(e: any) => {
							dispatch(editDraftShiftDialogOpen(data?.id.toString()));
							toggleVisibility(false);
						}}
						sx={{
							textTransform: "none",
							width: "100%",
							justifyContent: "flex-start",
							paddingLeft: "24px",
						}}
						disabled={
							Array.isArray(userRoles) ? userRoles.includes("ReadOnly") : false
						}
					>
						Edit Shift
					</Button>
				</>
			)}
			<Box>
				<Divider />
				<DeleteShift
					shiftData={data}
					toggleVisibility={toggleVisibility}
					openShiftCancelDialog={() => {
						setShiftIdForShiftDetailsDialog(shiftId);
						openShiftCancelDialog();
					}}
				/>
			</Box>
		</Box>
	);

	// For draft shifts
	if (!data?.isPublished) {
		return draftDataContent;
	} // For published shifts
	else return publishedDataContent;
}
